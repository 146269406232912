import { Box, TextField, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { profileTargetType } from '../../../@types/profile';
import axiosInstance from '../../../utils/axios';
import ChangePreview from './ChangePreview';
import EditorHeader from './EditorHeader';

export default function TargetEditor({
  target,
}: { target: profileTargetType | null }) {
  const [initStatus, setInitStatus] = useState(true);
  const [targetText, setTargetText] = useState<string>(
    target ? target.value : '',
  );
  const [date, setDate] = useState(
    target ? dayjs(target.updatedAt).format('DD/MM/YYYY') : '',
  );

  async function onSave() {
    await axiosInstance.put('/users/profile/target', { value: targetText });
    setInitStatus(true);
  }

  function onChange(text: string) {
    setTargetText(text);
    setInitStatus(false);
    setDate(dayjs(new Date()).format('DD/MM/YYYY'));
  }

  return (
    <Box>
      <EditorHeader
        title={'เป้าหมายการเรียนรู้ / สิ่งที่คาดหวัง'}
        onSubmit={() => onSave()}
        disabled={initStatus}
        icon="fa6-solid:trophy"
        secondarySection={
          <Typography
            variant="h4"
            sx={{ color: 'text.secondary' }}
          >{`(${targetText.length}/50)`}</Typography>
        }
      />
      <TextField
        value={targetText}
        onChange={(e) => onChange(e.target.value)}
        sx={{ textAlign: 'left', width: '100%', marginTop: '10px' }}
        placeholder={
          'ระบุทักษะที่ต้องการพัฒนา อาชีพที่คุณต้องการย้ายสายงาน หรือสิ่งที่คุณคาดหวังจากการเรียน ฯลฯ'
        }
        inputProps={{ maxLength: 50 }}
      />
      <ChangePreview>
        <Typography variant="h6">
          “{targetText}” - เมื่อวันที่ {date}
        </Typography>
      </ChangePreview>
    </Box>
  );
}
