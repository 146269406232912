import {
  Box,
  Button,
  Card,
  DialogContent,
  Modal,
  OutlinedInput,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import axiosInstance from '../../utils/axios';
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

const styles = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  width: '100%',
  backgroundColor: 'grey.800',
};

export function TargetProfileModal({
  handleRefreshProfileData,
}: {
  handleRefreshProfileData: () => void;
}) {
  const { user, isInitialized } = useAuth();

  useEffect(() => {
    isInitialized && setOpen(user?.target ? false : true);
  }, [user]);

  const [open, setOpen] = useState(false);
  const onClose = () => {
    handleRefreshProfileData();
    setOpen(false);
  };
  return (
    <Modal open={open} aria-labelledby="alert-dialog-title">
      <Card sx={{ minWidth: '300px', maxWidth: '480px', ...styles }}>
        <DialogContent>
          <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>
            ดูเหมือนว่าคุณจะยังไม่ได้ระบุเป้าหมายในการเรียน
          </Typography>
          <Typography sx={{ textAlign: 'center', fontSize: '14px' }}>
            ระบุทักษะที่ต้องการพัฒนา อาชีพที่คุณต้องการย้ายสายงาน หรือสิ่งที่คุณคาดหวังจากการเรียน
            ฯลฯ เพื่อที่เราจะสนับสนุนคุณได้มากขึ้น!
          </Typography>
          <TargetEditor setClose={onClose} />
        </DialogContent>
      </Card>
    </Modal>
  );
}

export default function TargetEditor({ setClose }: { setClose: () => void }) {
  const [targetText, setTargetText] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const { initialize } = useAuth();

  async function onSave() {
    setLoading(true);
    try {
      await axiosInstance.put('/users/profile/target', { value: targetText });
      await initialize();
      setClose();
    } catch {
      return;
    }
  }

  function onChange(text: string) {
    setTargetText(text);
  }

  const disabled = targetText.length <= 0;

  return (
    <>
      <OutlinedInput
        value={targetText}
        onChange={(e) => onChange(e.target.value)}
        startAdornment={
          <Iconify
            width={16}
            height={16}
            icon="fa6-solid:medal"
            sx={{ marginRight: '10px' }}
          />
        }
        sx={{
          textAlign: 'left',
          width: '100%',
          marginTop: '15px',
          overflow: 'hidden',
          color: 'black',
          background: 'white',
          '&& input': {
            paddingY: '8.5px',
          },
        }}
        inputProps={{ maxLength: 50 }}
      />
      <Box
        sx={{
          fontSize: '12px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography sx={{ fontSize: '12px', color: 'error.main' }}>
          {disabled ? 'กรุณาระบุเป้าหมายของคุณลงในช่องว่าง' : ''}
        </Typography>
        <Typography
          sx={{ color: 'text.secondary', fontSize: '12px', fontWeight: 'bold' }}
        >{`(${targetText.length}/50)`}</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          disabled={disabled || loading}
          onClick={onSave}
          variant="contained"
          sx={{ marginTop: '20px' }}
        >
          ยืนยัน
        </Button>
      </Box>
    </>
  );
}
