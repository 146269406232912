import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

import { ProjectDetailType } from '../../@types/projectGallery';
import Icon from '../../components/Iconify';
import Image from '../../components/Image';
import GeneralLink from '../../components/link/General';
import TextLink from '../../components/link/Text';
import ImageModal from '../../components/modal/ImageModal';
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
import { fDateThai } from '../../utils/formatTime';

import './index.css';
import TargetBadge from '../../components/user/TargetBadge';
import {
  ProfileDetail,
  ProfileDetailProps,
  ProfileDetailTooltip,
} from '../profile/ProfileDetailTooltip';
import { getLinkIcon } from './projectConfig';

interface PropsProjectOverview {
  width: number;
  projectDetail: ProjectDetailType;
  imageSelected: string;
  setImageSelected: Function;
  favoriteAction: Function;
  saveAction: Function;
  workLink: string;
}

interface PropsProjectDetail {
  projectName: string;
  courseTitle: string;
  courseSlug: string;
  favoriteCount: number;
  viewCount: number;
  courseDescription: string;
}

interface PropsProjectHeader {
  createdDate: string;
  isAuth: boolean;
  isSave: number;
  saveAction: Function;
  isFav: number;
  favAction: Function;
  workLink: string;
  user: ProfileDetailProps;
}

const FavoriteButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean; scrollDown: boolean; mobileSm: boolean }>(
  ({ isActive, scrollDown, mobileSm, theme }) => ({
    height: scrollDown ? '32px' : '48px',
    width: scrollDown ? (mobileSm ? '' : '83px') : '99px',
    borderRadius: '8px',
    color: theme.palette.common.white,
    backgroundColor: isActive
      ? theme.palette.primary.main
      : theme.palette.grey[700],
    display: 'flex',
    alignItems: 'center',
    ':hover': {
      backgroundColor: isActive
        ? theme.palette.primary.dark
        : theme.palette.grey[800],
    },
  }),
);

const ViewButton = styled(Button)<{ scrollDown: boolean; mobileSm: boolean }>(
  ({ scrollDown, mobileSm, theme }) => ({
    height: scrollDown ? '32px' : '48px',
    width: scrollDown ? (mobileSm ? '' : '85px') : '99px',
    borderRadius: '8px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[700],
    display: 'flex',
    alignItems: 'center',
    ':hover': {
      backgroundColor: theme.palette.grey[800],
    },
  }),
);

export default function ProjectOverview({
  width,
  projectDetail,
  imageSelected,
  setImageSelected,
  favoriteAction,
  saveAction,
  workLink,
}: PropsProjectOverview) {
  const { isAuthenticated } = useAuth();

  const [imageIndex, setImageIndex] = useState<number | string>('video');
  const [videoError, setVideoError] = useState<boolean>(false);
  const [videoPlay, setVideoPlay] = useState<boolean>(true);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [imageHeight, setImageHeight] = useState<number>(0);
  const [imageWidth, setImageWidth] = useState<number>(0);
  useEffect(() => {
    const img = document.createElement('img');
    img.src = imageSelected;
    setImageHeight(img.height);
    setImageWidth(img.width);
  }, [imageSelected]);

  return (
    <Box>
      <ProjectHeader
        createdDate={fDateThai(projectDetail?.checkedAt)}
        isAuth={isAuthenticated}
        isSave={projectDetail?.isSave}
        saveAction={saveAction}
        isFav={projectDetail?.isFavorite}
        favAction={favoriteAction}
        workLink={workLink}
        user={projectDetail?.user}
      />

      <Box
        sx={{
          width: '100%',
          height: `${width + 100}px`,
          maxHeight: '600px',
          backgroundColor: 'background.paper',
          position: 'relative',
          cursor: 'pointer',
        }}
      >
        {!videoError ? (
          <ReactPlayer
            url={projectDetail.youtubeLink}
            style={{ display: imageIndex === 'video' ? 'block' : 'none' }}
            controls={true}
            playing={videoPlay}
            width="100%"
            height="100%"
            onError={() => setVideoError(true)}
          />
        ) : (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: '100%',
              height: '100%',
              color: 'grey.600',
              display: imageIndex === 'video' ? null : 'none',
            }}
          >
            <Stack direction="row" alignItems="center" sx={{ gap: '5px' }}>
              <Icon
                icon="ep:warning-filled"
                sx={{ width: '30px', height: '30px' }}
              />
              <Typography variant="subtitle1">วิดีโอไม่สามารถเปิดได้</Typography>
            </Stack>
            <Button
              href={projectDetail.youtubeLink}
              disableRipple={true}
              sx={{
                textDecoration: 'none',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                ':hover': {
                  backgroundColor: 'transparent',
                  textDecoration: 'underline',
                },
              }}
            >
              {projectDetail.youtubeLink}
            </Button>
          </Stack>
        )}

        {imageIndex !== 'video' && (
          <>
            <Box
              sx={{
                backgroundImage: `url(${imageSelected})`,
                backgroundRepeat: 'no-repeat',
                width: '100%',
                height: '100%',
                backgroundColor: 'background.paper',
                backgroundPosition: 'center',
                ...(imageHeight > 600 || imageWidth > width
                  ? { backgroundSize: 'contain' }
                  : {}),
              }}
            />
            <Image
              alt="project-image"
              src={imageSelected}
              sx={{
                width: '100%',
                height: '100%',
                opacity: 0,
                position: 'absolute',
                zIndex: 1,
                top: 0,
              }}
              onClick={handleOpen}
            />
          </>
        )}
      </Box>
      <ImageModal src={imageSelected} open={open} handleClose={handleClose} />

      <Box sx={{ marginTop: '57px', width: '100%' }}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            overflow: 'auto',
            paddingBottom: '10px',
            '&::-webkit-scrollbar': {
              width: '0.4em',
              height: '0.5em',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'translate',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'grey.700',
              borderRadius: '6px',
              ':hover': {
                backgroundColor: 'grey.800',
              },
            },
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              gap: '15px',
              margin: 'auto',
            }}
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '152px',
                height: '152px',
                borderRadius: '8px',
                border: '3px solid',
                borderColor:
                  imageIndex === 'video' ? 'primary.main' : 'transparent',
                cursor: 'pointer',
              }}
            >
              <Box
                sx={{
                  width: '142px',
                  height: '142px',
                  borderRadius: '8px',
                  backgroundColor: 'grey.800',
                  position: 'relative',
                }}
                onClick={() => {
                  setImageIndex('video');
                  setVideoPlay(true);
                }}
              >
                <Icon
                  icon="bi:play-circle-fill"
                  sx={{
                    width: '60px',
                    height: '60px',
                    color: 'grey.500',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              </Box>
            </Stack>

            {[projectDetail.coverImage, ...projectDetail.worksImages]?.map(
              (item: string | undefined, index: number) => (
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  key={`project-image-${index}`}
                  sx={{
                    width: '152px',
                    height: '152px',
                    borderRadius: '8px',
                    border: '3px solid',
                    transition: '.3s',
                    borderColor:
                      imageIndex === index ? 'primary.main' : 'transparent',
                    cursor: 'pointer',
                  }}
                >
                  <Image
                    alt="project-image"
                    key={`image-preview-${index}`}
                    src={item}
                    sx={{
                      width: '142px',
                      height: '142px',
                      borderRadius: '8px',
                    }}
                    onClick={() => {
                      setImageSelected(item);
                      setImageIndex(index);
                      setVideoPlay(false);
                    }}
                  />
                </Stack>
              ),
            )}
          </Stack>
        </Stack>
      </Box>

      <ProjectDetail
        projectName={projectDetail?.name}
        courseTitle={projectDetail.project.course.title}
        courseSlug={projectDetail.project.course.slug}
        favoriteCount={projectDetail.favorite}
        viewCount={projectDetail.view}
        courseDescription={projectDetail?.description}
      />
    </Box>
  );
}

function ProjectDetail({
  projectName,
  courseTitle,
  courseSlug,
  favoriteCount,
  viewCount,
  courseDescription,
}: PropsProjectDetail) {
  return (
    <Box sx={{ marginTop: '80px' }}>
      <Box>
        <Typography variant="h3">{projectName}</Typography>
        <Box sx={{ marginLeft: '10px', display: 'flex', gap: '5px' }}>
          <Typography variant="body1" sx={{ color: 'text.secondary' }}>
            ผลงานจากคอร์สเรียน
          </Typography>
          <Typography
            component={Link}
            to={`/course/${courseSlug}`}
            variant="body1"
            sx={{
              color: 'text.secondary',
              textDecoration: 'none',
              ':hover': { color: 'primary.main' },
            }}
          >
            {courseTitle}
          </Typography>
        </Box>
      </Box>

      <Stack
        direction="row"
        sx={{ gap: '25px', marginTop: '10px', color: 'text.secondary' }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ gap: '5px' }}
        >
          <Icon
            icon="heroicons-solid:thumb-up"
            sx={{ width: '25px', height: '25px', marginTop: '-5px' }}
          />
          <Typography variant="body1">{favoriteCount}</Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ gap: '5px' }}
        >
          <Icon icon="bi:eye-fill" sx={{ width: '25px', height: '25px' }} />
          <Typography variant="body1">{viewCount}</Typography>
        </Stack>
      </Stack>

      <Typography
        variant="h4"
        sx={{
          fontWeight: 'regular',
          marginTop: '25px',
          whiteSpace: 'pre-line',
          wordBreak: 'break-word',
        }}
      >
        {courseDescription}
      </Typography>
    </Box>
  );
}

function ProjectHeader({
  createdDate,
  isAuth,
  isFav,
  favAction,
  workLink,
  user,
}: PropsProjectHeader) {
  const mobile = useResponsive('up', 1200) ? false : true;
  const mobileSm = useResponsive('up', 453) ? false : true;
  const endBreak = useResponsive('up', 457) ? false : true;
  const [scrollPosition, setScrollPosition] = useState(0);
  useEffect(() => {
    const updateScrollPosition = () => {
      setScrollPosition(window.pageYOffset);
    };
    window.addEventListener('scroll', updateScrollPosition);
    return () => {
      window.removeEventListener('scroll', updateScrollPosition);
    };
  }, []);
  return (
    <Box
      sx={{ minWidth: '100%', maxWidth: 'auto' }}
      className={scrollPosition > 0 ? 'AppBar-root fixed' : 'AppBar-root'}
    >
      <Container disableGutters={!mobile}>
        <Grid
          container
          justifyContent="space-between"
          sx={{
            marginBottom: scrollPosition > 0 ? '10px' : '40px',
            gap: '30px',
          }}
        >
          <Grid item xs sm>
            <Stack direction="row" spacing={2}>
              {scrollPosition > 0 ? (
                !mobileSm ? (
                  <>
                    {user.role === 'STUDENT' ? (
                      <GeneralLink link={user.id ? `/profile/${user.id}` : ''}>
                        <ProfileDetailTooltip
                          title={ProfileDetail(user)}
                          placement="top-start"
                        >
                          <Avatar
                            src={user.imageUrl}
                            alt="user-image"
                            sx={{
                              width: '45px',
                              height: '45px',
                              cursor: 'pointer',
                            }}
                          />
                        </ProfileDetailTooltip>
                      </GeneralLink>
                    ) : (
                      <Avatar
                        src={user.imageUrl}
                        alt="user-image"
                        sx={{ width: '45px', height: '45px' }}
                      />
                    )}
                  </>
                ) : (
                  <></>
                )
              ) : (
                <>
                  {user.role === 'STUDENT' ? (
                    <GeneralLink link={user.id ? `/profile/${user.id}` : ''}>
                      <ProfileDetailTooltip
                        title={ProfileDetail(user)}
                        placement="top-start"
                      >
                        <Avatar
                          src={user.imageUrl}
                          alt="user-image"
                          sx={{
                            width: '64px',
                            height: '64px',
                            cursor: 'pointer',
                          }}
                        />
                      </ProfileDetailTooltip>
                    </GeneralLink>
                  ) : (
                    <Avatar
                      src={user.imageUrl}
                      alt="user-image"
                      sx={{ width: '64px', height: '64px' }}
                    />
                  )}
                </>
              )}
              <Stack direction="column">
                <Box>
                  <TargetBadge target={user?.target || null} />
                  {user.role === 'STUDENT' ? (
                    <>
                      <ProfileDetailTooltip
                        title={ProfileDetail(user)}
                        placement="top-start"
                      >
                        <Box>
                          <TextLink
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: 'vertical',
                              cursor: 'pointer',
                            }}
                            link={
                              user.id && user.role === 'STUDENT'
                                ? `/profile/${user.id}`
                                : ''
                            }
                            text={user.publicName}
                            typographyVariant={scrollPosition > 0 ? 'h5' : 'h3'}
                          />
                        </Box>
                      </ProfileDetailTooltip>
                      <Typography
                        variant={scrollPosition > 0 ? 'body2' : 'body1'}
                      >
                        {createdDate}
                      </Typography>
                    </>
                  ) : (
                    <Box>
                      <Typography variant={scrollPosition > 0 ? 'h5' : 'h3'}>
                        {user.publicName}
                      </Typography>
                      <Typography
                        variant={scrollPosition > 0 ? 'body2' : 'body1'}
                      >
                        {createdDate}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Stack>
            </Stack>
          </Grid>

          {isAuth && (
            <Grid item sm>
              <Stack
                direction="row"
                justifyContent={
                  !endBreak || scrollPosition > 0 ? 'end' : 'start'
                }
                spacing={2}
                alignItems="center"
              >
                <a
                  href={
                    workLink.includes('https://')
                      ? workLink
                      : `https://${workLink}`
                  }
                  style={{ textDecoration: 'none' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ViewButton
                    scrollDown={scrollPosition > 0}
                    mobileSm={mobileSm}
                  >
                    <Icon
                      icon={getLinkIcon(workLink)}
                      sx={{
                        width: '21px',
                        height: '21px',
                        marginRight: !mobileSm ? '5px' : '',
                      }}
                    />
                    <Typography variant="button">
                      {!mobileSm ? 'Project' : ''}
                    </Typography>
                  </ViewButton>
                </a>
                <FavoriteButton
                  scrollDown={scrollPosition > 0}
                  mobileSm={mobileSm}
                  isActive={isFav === 1}
                  onClick={() => favAction()}
                >
                  <Typography variant="button">
                    {!mobileSm ? 'Like' : ''}
                  </Typography>
                  <Icon
                    icon="heroicons-solid:thumb-up"
                    sx={{
                      width: '21px',
                      height: '21px',
                      marginBottom: '5px',
                      marginLeft: !mobileSm ? '5px' : '',
                    }}
                  />
                </FavoriteButton>
              </Stack>
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
}
