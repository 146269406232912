import {
  Avatar,
  Box,
  Grid,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import React from 'react';
import { profileExperienceType, profileTargetType } from '../../@types/profile';
import Iconify from '../../components/Iconify';
import TextLink from '../../components/link/Text';

export interface ProfileDetailProps {
  id?: string;
  imageUrl: string;
  publicName: string;
  anonymous?: boolean;
  role: string;
  experience?: profileExperienceType;
  target?: profileTargetType;
}

const descriptionStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-word',
};

export const ProfileDetailTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: '400px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.5)',
  },
}));

export function ProfileDetail(user: ProfileDetailProps) {
  return (
    <Box>
      <Stack direction="row" paddingTop={1} paddingBottom={2}>
        <Avatar
          className="flex"
          alt={user.publicName}
          src={user.imageUrl}
          sx={{ width: '60px', height: '60px', marginRight: '16px' }}
        />
        <Box>
          <TextLink
            link={user.id ? `/profile/${user.id}` : ''}
            text={user.publicName}
            typographyVariant="h5"
          />

          <Box paddingTop={0.5}>
            <Grid container spacing={1} flexWrap={'nowrap'}>
              <Grid item>
                <Iconify
                  color={'text.primary'}
                  icon={'ic:round-work'}
                  width={30}
                  height={30}
                />
              </Grid>
              <Grid item>
                <Typography variant="caption" color={'primary.main'}>
                  ตำแหน่งงานปัจจุบัน
                </Typography>
                <Typography
                  color={'text.secondary'}
                  sx={{ ...descriptionStyle }}
                >
                  {user.experience?.position
                    ? user.experience.position
                    : 'ไม่ระบุ'}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box paddingTop={0.5}>
            <Grid container spacing={1} flexWrap={'nowrap'}>
              <Grid item>
                <Iconify
                  color={'text.primary'}
                  icon={'fa6-solid:medal'}
                  width={30}
                  height={30}
                />
              </Grid>
              <Grid item>
                <Typography variant="caption" color={'primary.main'}>
                  เป้าหมาย
                </Typography>
                <Typography
                  sx={{ ...descriptionStyle }}
                  color={'text.secondary'}
                >
                  {user.target?.value ? user.target.value : 'ไม่ระบุ'}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
}
