import { Avatar, Box, Button, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  profileCoverImageType,
  profileExperienceType,
  profileStatusType,
  profileTargetType,
  profileUserType,
} from '../../../@types/profile';
import { ConfirmationDialog } from '../../../components/Dialog';
import Iconify from '../../../components/Iconify';
import TargetBadge from '../../../components/user/TargetBadge';
import useAuth from '../../../hooks/useAuth';
import axiosInstance from '../../../utils/axios';

interface ProfileCoverProps {
  userData: profileUserType;
  profileStatus: profileStatusType[];
  target: profileTargetType | null;
  experience: profileExperienceType | null;
  coverImage: profileCoverImageType | null;
  swapToEdit: () => null;
}

interface ProfileStatusProps {
  text: string;
}

export default function ProfileCover({
  userData,
  profileStatus,
  target,
  experience,
  coverImage,
  swapToEdit,
}: ProfileCoverProps) {
  const { user } = useAuth();
  const theme = useTheme();

  const isOwner = () => user?.id === userData.id;
  const [isDefault, setIsDefault] = useState(coverImage === null);

  const getExperienceText = (experience: profileExperienceType) => {
    const positionStatusText = experience.isCurrent
      ? 'ตำแหน่งงานปัจจุบัน'
      : 'ประสบการณ์ทำงาน';
    const positionText = `${experience.employmentTypeTitle || ''} ${
      experience.position || ''
    }`;
    const duration = dayjs(experience.endDate || new Date()).diff(
      dayjs(dayjs(experience.startDate)),
      'year',
    );
    const durationText = duration > 0 ? ` (ประสบการณ์รวม ${duration} ปี)` : '';
    return `${positionStatusText} : ${positionText}${durationText}`;
  };

  useEffect(() => {
    setIsDefault(coverImage === null);
  }, [coverImage]);

  return (
    <Box sx={{ position: 'relative', height: '100%', marginBottom: '20px' }}>
      <Box sx={{ top: 10, right: 10, position: 'absolute' }}>
        <AdminCoverDeleter
          profileId={userData.id}
          isDefault={isDefault}
          setIsDefault={setIsDefault}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '300px',
          backgroundImage: `linear-gradient(${alpha(
            theme.palette.background.default,
            0,
          )}, ${alpha(theme.palette.background.default, 0.7)}), url("${
            !isDefault && coverImage ? coverImage.url : '/images/orange-bg.png'
          }")`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '16px 16px 0px 0px',
          boxShadow: '0px 4px 4px #00000015',
        }}
      ></Box>
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            gap: '15px',
            margin: '-152px 0 0 20px',
            '@media (max-width: 599px)': {
              margin: '-237px 0 0 0',
              width: '100%',
              gap: '0px',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'center',
            },
          }}
        >
          <Avatar
            src={userData.imageUrl}
            alt="profile-image"
            sx={{ width: '200px', height: '200px' }}
          />
          <Box sx={{ width: '100%' }}>
            <Box sx={{ paddingTop: !target && !isOwner() ? '0px' : '10px' }}>
              <TargetBadge
                swapToEdit={swapToEdit}
                target={target}
                isOwner={isOwner()}
              />
            </Box>
            <Typography variant="h5" sx={{ wordBreak: 'break-word' }}>
              {userData.publicName}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: '5px',
                '@media (max-width: 599px)': {
                  justifyContent: 'center',
                },
              }}
            >
              {isOwner() && (
                <Typography
                  component={Link}
                  to="/subscription"
                  variant="overline"
                  sx={{
                    color: 'text.secondary',
                    textDecoration: 'none',
                    ':hover': { color: 'primary.main' },
                  }}
                >
                  {user?.subscription.plan.title}
                </Typography>
              )}
              {isOwner() && experience && (
                <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                  |
                </Typography>
              )}
              <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                {experience && getExperienceText(experience)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.default',
          paddingLeft: '230px',
          borderRadius: '0px 0px 16px 16px',
          minHeight: '32px',
          marginBottom: '50px',
          '@media (max-width: 599px)': {
            padding: '70px 0px 0px 0px',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '10px',
            paddingBottom: '5px',
            flexWrap: 'wrap',
            '@media (max-width: 599px)': {
              justifyContent: 'center',
            },
          }}
        >
          {profileStatus.map(
            (status, index) =>
              status.isActive && (
                <ProfileStatus
                  key={`status-${index + 1}`}
                  text={status.title}
                />
              ),
          )}
        </Box>
      </Box>
    </Box>
  );
}

export function ProfileStatus({ text }: ProfileStatusProps) {
  return (
    <Box sx={{ display: 'flex', gap: '10px', marginTop: '5px' }}>
      <Box
        sx={{
          minWidth: '20px',
          height: '20px',
          borderRadius: '50px',
          backgroundColor: 'success.main',
        }}
      ></Box>
      <Typography variant="subtitle2" sx={{ wordBreak: 'break-word' }}>
        {text}
      </Typography>
    </Box>
  );
}

function AdminCoverDeleter({
  profileId,
  isDefault,
  setIsDefault,
}: {
  profileId: string;
  isDefault: boolean;
  setIsDefault: Function;
}) {
  const { user } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  async function deleteCover() {
    try {
      await axiosInstance.delete(
        `/admin/users/${profileId}/profile/cover-image`,
      );
      setOpenModal(false);
      setIsDefault(true);
    } catch {
      return;
    }
  }
  if (user?.role === 'ADMIN') {
    return (
      <Box>
        <Button
          disabled={isDefault}
          onClick={() => setOpenModal(true)}
          variant="contained"
          color="error"
          sx={{ color: 'text.button', borderRadius: '8px' }}
        >
          <Iconify
            icon="material-symbols:delete-forever"
            sx={{ width: '20px', height: '20px' }}
          />
          ลบรูปปกเนื่องจากไม่เหมาะสม
        </Button>
        <ConfirmationDialog
          open={openModal}
          text="ยืนยันการลบรูปปก"
          handleConfirm={deleteCover}
          handleCancel={() => setOpenModal(false)}
        />
      </Box>
    );
  }

  return null;
}
