import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { profileTargetType } from '../../../@types/profile';

export default function Target({
  target,
}: { target: profileTargetType | null }) {
  if (!target) return <></>;
  return (
    <Box sx={{ marginY: '30px' }}>
      <Typography variant="h4">🎯 เป้าหมายการเรียนรู้ / สิ่งที่คาดหวัง</Typography>
      <Typography variant="h6">
        “{target.value}” - เมื่อวันที่ {dayjs(target.updatedAt).format('DD/MM/YYYY')}
      </Typography>
    </Box>
  );
}
