import { Box, Chip } from '@mui/material';
import React from 'react';
import { profileTargetType } from '../../@types/profile';
import Iconify from '../Iconify';

interface TargetBadgeProps {
  swapToEdit?: () => null;
  target: profileTargetType | null;
  isOwner?: boolean;
}

export default function TargetBadge({
  swapToEdit = () => null,
  target,
  isOwner = false,
}: TargetBadgeProps) {
  const config = {
    none: {
      boxShadow: { boxShadow: '0px 4px 6px 0px #00000066' },
      onClick: () => swapToEdit(),
      icon: 'tabler:edit',
      text: 'ระบุเป้าหมายของคุณ เพื่อที่เราจะสนับสนุนคุณได้มากขึ้น!',
    },
    set: {
      boxShadow: {},
      onClick: undefined,
      icon: 'fa6-solid:medal',
      text: target?.value,
    },
  };
  const selectedConfig = config[target?.value ? 'set' : 'none'];
  const hideBadge = !isOwner && !target?.value;
  return hideBadge ? (
    <></>
  ) : (
    <Box
      sx={{
        marginLeft: '-5px',
        padding: '0px',
        '@media (max-width: 599px)': { marginLeft: 0 },
      }}
    >
      <Chip
        onClick={selectedConfig.onClick}
        label={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
              textAlign: 'left',
            }}
          >
            <Iconify
              sx={{ fontSize: 12, fontWeight: 600, minWidth: '14px' }}
              icon={selectedConfig.icon}
              height={14}
              width={14}
            />
            {selectedConfig.text}
          </Box>
        }
        color={'primary'}
        size="small"
        sx={{
          fontSize: 12,
          fontWeight: 600,
          span: { padding: '3px 10px', margin: 0 },

          height: 'auto',
          '& .MuiChip-label': {
            display: 'block',
            whiteSpace: 'normal',
          },
          ...selectedConfig.boxShadow,
        }}
      />
    </Box>
  );
}
