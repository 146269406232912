import { Container } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import HelmetMetaData from '../../components/Helmet';
import useAuth from '../../hooks/useAuth';
import { RootState } from '../../redux/store';
import axiosInstance from '../../utils/axios';

import {
  setDevlabStatistic,
  setLoading,
  setProfileData,
  setProfileLoading,
  setUserProfile,
} from '../../redux/slices/profile';

import { TargetProfileModal } from '../../components/user/TargetProfileModal';
import EnrolledCourses from './EnrolledCourses';
import StatisticData from './StatisticData';
import ProfileController from './profileController';

export default function Profile() {
  const { user, isAuthenticated, initialize } = useAuth();
  const { userId } = useParams();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const { profileData, loading } = useSelector(
    (state: RootState) => state.profile,
  );

  const [profileId, setProfileId] = useState<string>('');
  const [editMode, setEditMode] = useState<boolean>(false);
  const isSelf = () => userId === user?.id || !userId;

  const getDevlabAccount = async (borntodevId: string) => {
    try {
      const response = await axiosInstance.get(
        `/devlab/account/${borntodevId}`,
      );
      const data = await response.data;
      dispatch(setDevlabStatistic(data));
    } catch {
      return;
    }
  };

  const getProfileData = async () => {
    try {
      const response = await axiosInstance.get(
        `/users/profile?userId=${profileId}`,
      );
      const data = await response.data;
      dispatch(setProfileData(data));
    } catch {
      navigate('404', { replace: true });
    }
  };

  const getProjectsData = async () => {
    try {
      const response = await axiosInstance.get(
        `/projects/gallery?sortBy=userFeatured&&userId=${profileId}`,
      );
      const data = await response.data;
      dispatch(setUserProfile(data));
    } catch {
      return;
    }
  };

  const handleRefreshProfileData = async () => {
    await dispatch(setProfileLoading(true));
    await Promise.all([getProfileData(), getProjectsData()]);
    await dispatch(setProfileLoading(false));
  };

  const handleEditMode = (mode: boolean) => {
    setEditMode(mode);
    if (!mode) handleRefreshProfileData();
  };

  useEffect(() => {
    (async () => {
      await dispatch(setLoading(true));
      if (profileId) {
        await handleRefreshProfileData();
        await dispatch(setLoading(false));
      }
    })();
  }, [profileId]);

  useEffect(() => {
    if (profileData?.user.borntodevId) {
      getDevlabAccount(profileData?.user.borntodevId ?? '');
    }
  }, [profileData?.user.borntodevId]);

  useEffect(() => {
    (() => {
      if (!loading) {
        const isContinue = Boolean(
          searchParams.get('target') === 'continueLearning',
        );
        if (isContinue) {
          document
            .getElementById('continueLearning')
            ?.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }

        window.history.replaceState({}, '', location.pathname);
      }
    })();
  }, [loading]);

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    userId ? setProfileId(userId) : user?.id && setProfileId(user?.id);
  }, [userId, user]);

  if (!isAuthenticated && !userId) {
    navigate('/login');
  }

  return (
    <>
      <HelmetMetaData />
      <Container sx={{ marginBottom: '50px', maxWidth: '1270px' }}>
        {isSelf() && (
          <TargetProfileModal
            handleRefreshProfileData={handleRefreshProfileData}
          />
        )}

        <ProfileController
          profileId={profileId}
          isSelf={isSelf()}
          editMode={editMode}
          setEditMode={handleEditMode}
        />
        {isSelf() && !editMode && <StatisticData />}
        {!editMode && profileId && (
          <EnrolledCourses isSelf={isSelf()} profileId={profileId} />
        )}
      </Container>
    </>
  );
}
