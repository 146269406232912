import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Pagination,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import {
  OverviewReviewType,
  ReviewCourseType,
  ReviewDetailType,
} from '../../../@types/reviewCourse';
import Icon from '../../../components/Iconify';
import ReviewRateStar from '../../../components/ReviewRateStar';
import GeneralLink from '../../../components/link/General';
import TextLink from '../../../components/link/Text';
import TargetBadge from '../../../components/user/TargetBadge';
import axiosInstance from '../../../utils/axios';
import { fTSeparator } from '../../../utils/formatNumber';
import {
  ProfileDetail,
  ProfileDetailTooltip,
} from '../../profile/ProfileDetailTooltip';

interface OverviewReviewProps {
  overviewData?: OverviewReviewType;
}

interface ReviewCommentProps {
  reviewData: ReviewDetailType;
}

interface ReviewComponentprops {
  courseId?: string;
  overviewData: OverviewReviewType;
}

function OverviewReview({ overviewData }: OverviewReviewProps) {
  return (
    <Box>
      <Box sx={{ display: 'flex', marginTop: '35px', gap: '5px' }}>
        <Typography variant="h4" sx={{ marginY: '5px' }}>
          รีวิวจากผู้เรียน
        </Typography>
        <Box>
          <Typography
            variant="caption"
            sx={{ color: 'grey.500', top: 0, left: 0 }}
          >{`(${fTSeparator(overviewData?.count || 0)} รีวิว)`}</Typography>
        </Box>
      </Box>
      <Grid
        container
        sx={{
          width: '100%',
          gap: '20px',
          marginLeft: '30px',
          marginTop: '15px',
        }}
      >
        <Typography variant="h2" sx={{ color: 'warning.main' }}>
          {overviewData?.avg}
        </Typography>
        <Box>
          <Stack direction="row" sx={{ marginTop: '5px' }}>
            {Array(5)
              .fill(0)
              .map((_, index: number) => (
                <ReviewRateStar
                  key={`review-rate-star-${index}`}
                  width="24px"
                  height="24px"
                  display={overviewData?.avg ? overviewData?.avg - index : 0}
                />
              ))}
          </Stack>
          <Typography
            variant="caption"
            sx={{ color: 'grey.500', marginLeft: '10px' }}
          >{`จาก ${fTSeparator(overviewData?.count || 0)} รีวิว`}</Typography>
        </Box>
      </Grid>
    </Box>
  );
}

function ReviewComment({ reviewData }: ReviewCommentProps) {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [heightComment, setHeightComment] = useState<number>();

  const [commentTextSyle, setCommentTextSyle] = useState({
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    lineHeight: '20px',
    overflow: 'hidden',
    height: 'auto',
    maxHeight: '60px',
    transition: 'max-height .1s ease-out',
    textOverflow: 'ellipsis',
  });

  useEffect(() => {
    setHeightComment(
      document.getElementById(`review-${reviewData.id}`)?.scrollHeight,
    );
  }, [reviewData]);

  const getDate = (value?: Date): string => {
    const date = new Date(value || '');
    const month: string[] = [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤศจิกายน',
      'ธันวาคม',
    ];
    return `${date.getDate()} ${month[date.getMonth()]} ${
      date.getFullYear() + 543
    }`;
  };
  const target =
    reviewData?.user.role === 'STUDENT' && reviewData?.user.target
      ? reviewData?.user.target
      : null;

  return (
    <Box sx={{ marginTop: '25px' }}>
      <Stack direction="row">
        {reviewData?.userId ? (
          <>
            {reviewData?.user.role === 'STUDENT' ? (
              <GeneralLink
                link={
                  reviewData?.userId ? `/profile/${reviewData?.userId}` : ''
                }
              >
                <ProfileDetailTooltip
                  title={ProfileDetail(reviewData?.user)}
                  placement="top-start"
                >
                  <Avatar
                    alt={reviewData?.user.publicName}
                    src={reviewData?.user.imageUrl}
                    sx={{ width: '48px', height: '48px', marginRight: '16px' }}
                  />
                </ProfileDetailTooltip>
              </GeneralLink>
            ) : (
              <Avatar
                alt={reviewData?.user.publicName}
                src={reviewData?.user.imageUrl}
                sx={{ width: '48px', height: '48px', marginRight: '16px' }}
              />
            )}
          </>
        ) : (
          <Avatar
            alt={reviewData?.user.publicName}
            sx={{ width: '48px', height: '48px', marginRight: '16px' }}
          />
        )}
        <Stack direction="column">
          <TargetBadge target={target} />
          {reviewData?.userId ? (
            <>
              {reviewData?.user.role === 'STUDENT' ? (
                <ProfileDetailTooltip
                  title={ProfileDetail(reviewData?.user)}
                  placement="top-start"
                >
                  <Box>
                    <TextLink
                      link={
                        reviewData?.userId
                          ? `/profile/${reviewData?.userId}`
                          : ''
                      }
                      text={reviewData?.user.publicName}
                      typographyVariant="subtitle1"
                    />
                  </Box>
                </ProfileDetailTooltip>
              ) : (
                <TextLink
                  link={''}
                  text={reviewData?.user.publicName}
                  typographyVariant="subtitle1"
                />
              )}
            </>
          ) : (
            <TextLink
              link={reviewData?.userId ? `/profile/${reviewData?.userId}` : ''}
              text={reviewData?.user.publicName}
              typographyVariant="subtitle1"
            />
          )}

          <Stack direction="row" sx={{ margin: '2px 0px' }}>
            {Array(5)
              .fill(true, 0, reviewData.rate)
              .fill(false, reviewData.rate, 5)
              .map((rate: boolean, index: number) => (
                <Icon
                  key={`rate-reveiw-comment-${index}`}
                  icon="ant-design:star-filled"
                  sx={{
                    width: '18px',
                    height: '18px',
                    color: rate ? 'warning.main' : 'grey.500',
                  }}
                />
              ))}
          </Stack>

          <Typography
            variant="caption"
            sx={{ marginBottom: '8px', color: 'text.secondary' }}
          >
            {getDate(reviewData.createdAt)}
          </Typography>

          <Typography
            id={`review-${reviewData.id}`}
            variant="body2"
            sx={{ ...commentTextSyle }}
          >
            {reviewData.review}
          </Typography>

          {heightComment && heightComment - 2 > 60 ? (
            <Button
              variant="text"
              disableRipple={true}
              sx={{
                width: '90px',
                textAlign: 'left',
                color: 'grey.500',
                padding: '0px',
                marginLeft: '5px',
                ':hover': {
                  backgroundColor: 'transparent',
                  color: 'grey.600',
                },
              }}
              onClick={() => {
                setShowMore((prevState) => !prevState);
                !showMore
                  ? setCommentTextSyle({
                      ...commentTextSyle,
                      maxHeight: '100%',
                    })
                  : setCommentTextSyle({
                      ...commentTextSyle,
                      maxHeight: '60px',
                    });
              }}
            >
              {!showMore ? '...อ่านเพิ่มเติม' : '...เเสดงน้อยลง'}
            </Button>
          ) : null}
        </Stack>
      </Stack>

      <Divider sx={{ marginTop: '20px' }} />
    </Box>
  );
}

export default function Review({
  courseId,
  overviewData,
}: ReviewComponentprops) {
  const [loading, setLoading] = useState<boolean>(false);
  const [reviewData, setReviewData] = useState<ReviewCourseType>();
  const [page, setPage] = useState(1);
  const take = 5;

  const fetchReviewData = async (nextPage: number) => {
    try {
      setLoading(true);
      const response = await axiosInstance(
        `/courses/${courseId}/review?page=${nextPage}&take=${take}`,
      );
      const data = await response.data;
      setReviewData(data);
      setLoading(false);
    } catch {
      return;
    }
  };

  useEffect(() => {
    fetchReviewData(1);
  }, []);

  if (reviewData?.data && reviewData?.data.length > 0) {
    return (
      <>
        <OverviewReview overviewData={overviewData} />
        <Typography variant="h4" sx={{ marginTop: '36px' }}>
          ความคิดเห็น
        </Typography>
        {reviewData?.data.map((reviewItem: ReviewDetailType, index: number) => (
          <ReviewComment
            key={`review-comment-${index}`}
            reviewData={reviewItem}
          />
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
          <Pagination
            disabled={loading}
            count={reviewData.meta.pageCount}
            onChange={(_, value) => {
              setPage(value);
              fetchReviewData(value);
            }}
            page={page}
          />
        </Box>
      </>
    );
  }

  return null;
}
